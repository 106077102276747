import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Roles } from './models/roles.enum';
import {BehaviorSubject, catchError, map, Observable, of, tap} from 'rxjs';
import { environment } from '../../environments/environment';
import { LogginedUser } from './models/user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authUrl = `${environment.apiUrl}`;
  logginedUser: any;

  private loggedUser: BehaviorSubject<LogginedUser> = new BehaviorSubject<LogginedUser>(null);
  public loggedUser$: Observable<LogginedUser> = this.loggedUser.asObservable();

  constructor(
      public http: HttpClient,
      private router: Router
  ) {
  }

  get isUserLogged(): boolean {
    return !!this.getToken();
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  get roles(): Roles[] {
    const token = this.getToken();
    if (token) {
      return this.parseJwt(token).roles;
    }
    return [];
  }

  public refreshLoggedUser() {
    this.getLogginedUser(true).subscribe()
  }

  public getLogginedUser(force: boolean = false): Observable<LogginedUser> {
    if (!this.isUserLogged) {
      return of(null);
    }
    if (this.logginedUser && !force) {
      return of(this.logginedUser);
    }
    return this.http.get(`${this.authUrl}users/me`)
        .pipe(
            map((res: { data: LogginedUser }) => {
              if (!res.data) {
                return null;
              }
              this.logginedUser = new LogginedUser(res?.data);
              this.loggedUser.next(this.logginedUser)
              return this.logginedUser;
            }));
  }

  public signIn(email: string, password: string): Observable<any> {
    const payload = { email, password };
    return this.http.post(`${this.authUrl}auth/sign-in`, payload);
  }

  public forgotPassword(email: string): Observable<{
    message: string
  }> {
    const payload = { email };
    return this.http.post<{ message: string }>(`${this.authUrl}forgot-password`, payload);
  }

  public clearData(): void {
    localStorage.clear();
    this.logginedUser = null;
  }

  public signOut(): Observable<any> {
    this.logginedUser = null;
    return this.http.post(`${this.authUrl}auth/sign-out`, {})
        .pipe(
            tap(() => {
              this.clearData();
              this.router.navigate(['/signin']);
            }),
            catchError((error) => {
              this.clearData();
              this.router.navigate(['/signin']);
              return of(error);
            })
        );
  }

  parseJwt(token): {
    roles: Roles[],
  } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public resetPassword(token: string, password: string): Observable<{
    message: string
  }> {
    const payload = {
      password,
      passwordRepeat: password,
      token,
    };
    return this.http.post<{ message: string }>(`${this.authUrl}restore-password`, payload);
  }
}
