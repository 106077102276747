import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateResponse, GetOneResponse, Pagination, PaginationResponse, UpdateResponse } from './models/pagination.model';

@Injectable()
export class CrudService<T> {

  public domainEntity: string;
  public urlToEntity: string;

  constructor(protected http: HttpClient) {
    this.setSourceService();
  }

  getAll(filter = {}): Observable<PaginationResponse<T>> {

    const filterParams = filter ?
      `${Object.keys(filter)
        .filter(key => filter[key] !== null && filter[key] !== undefined)
        .map(key => `${key}=${filter[key]}`)
        .join('&')}`
      : '';

    return this.http.get<PaginationResponse<T>>(this.urlToEntity + '?' + filterParams);
  }

  getAllPaginated(
    pagination: Pagination = new Pagination()
  ): Observable<PaginationResponse<T>> {
    const paginationParams = `size=${pagination.size}&start=${pagination.start * pagination.size}`;
    const sortParams = pagination.sort ? `&sort=${pagination.sort.prop}&order=${pagination.sort.order}` : '';
    const filterParams = pagination.filter ?
      `&${Object.keys(pagination.filter)
        .filter(key => pagination.filter[key])
        .map(key => `${key}=${pagination.filter[key]}`)
        .join('&')}`
      : '';

    return this.http.get<PaginationResponse<T>>(this.urlToEntity + '?' + paginationParams + filterParams + sortParams)
      .pipe(
        map((response) => {
          return {
            data: !response?.data?.length ? [] : response.data,
            pagination: response.pagination
          }
        })
      )
  }

  getById(id: number): Observable<GetOneResponse<T>> {
    return this.http.get<GetOneResponse<T>>(`${this.urlToEntity}/${id}`);
  }

  create(entity: Partial<T>): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(this.urlToEntity, entity);
  }

  update(id: number, entity: Partial<T>): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(`${this.urlToEntity}/${id}`, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.urlToEntity}/${id}`)
  }

  protected setSourceService() {
    this.urlToEntity = `${environment.apiUrl}${this.domainEntity}`;
  }
}
