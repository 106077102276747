import { Roles } from "./roles.enum";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export class LogginedUser extends User {
  role: { id: number, role: Roles };
  createdAt: Date;
  updatedAt?: Date;

  constructor(data: LogginedUser) {
    super();
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.role = data.role;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : undefined;
  }

  get fullName(): string {
    return this.firstName && this.lastName ?
      `${this.firstName} ${this.lastName}` : (this.firstName || this.lastName);
  }

  get roleName(): Roles {
    return this.role.role;
  }
}
