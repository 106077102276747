import { Roles } from "./roles.enum";
import { Permissions } from "./permissions.enum";

export const PermissionRolesMap = {
  [Permissions.LIST_STUDIES]: [Roles.Admin, Roles.StudyManager],
  [Permissions.ADD_STUDY]: [Roles.Admin],
  [Permissions.VIEW_EDIT_STUDY]: [Roles.Admin],
  [Permissions.DELETE_STUDY]: [Roles.Admin],
  [Permissions.LIST_SITES]: [Roles.Admin],
  [Permissions.ADD_SITE]: [Roles.Admin],
  [Permissions.EDIT_SITE]: [Roles.Admin],
  [Permissions.DELETE_SITE]: [Roles.Admin],
  [Permissions.ADD_CLINICAL_DEVICE]: [Roles.Admin],
  [Permissions.REMOVE_CLINICAL_DEVICE]: [Roles.Admin],
  [Permissions.REMOVE_CLINICAL_DEVICES]: [Roles.Admin],
  [Permissions.LIST_PARTICIPANTS]: [Roles.Admin, Roles.StudyManager],
  [Permissions.REGISTER_PARTICIPANT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.EDIT_PARTICIPANT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.LIST_PARTICIPANT_SESSIONS]: [Roles.Admin, Roles.StudyManager],
  [Permissions.WITHDRAW_PARTICIPANT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.COMPLETE_PARTICIPANT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.LIST_ADVERSE_EVENTS]: [Roles.Admin, Roles.StudyManager],
  [Permissions.ADD_ADVERSE_EVENT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.EDIT_ADVERSE_EVENT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.DELETE_ADVERSE_EVENT]: [Roles.Admin, Roles.StudyManager],
  [Permissions.LIST_SESSIONS]: [Roles.Admin, Roles.StudyManager],
  [Permissions.CALENDAR]: [Roles.Admin, Roles.StudyManager],
  [Permissions.SCHEDULE_SESSION]: [Roles.Admin, Roles.StudyManager],
  [Permissions.RESCHEDULE_SESSION]: [Roles.Admin, Roles.StudyManager],
  [Permissions.CANCEL_SESSION]: [Roles.Admin, Roles.StudyManager],
  [Permissions.UPDATE_SESSION_DATA]: [Roles.Admin, Roles.StudyManager],
  [Permissions.COMPLETE_SESSION]: [Roles.Admin, Roles.StudyManager],
};
