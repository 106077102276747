import { User } from "./user.model";


export class StudyMember {
  id: number;
  phoneNumber?: string;
  dateOfBirth?: string;
  user: User;
}

export enum StudyParticipantStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export class StudySite {
  id: number;
  name: string;
  site: {
    id: number;
    name: string
  };
  study: {
    id: number;
    name: string
  };
}
export class StudyParticipant {
  id: number;
  studySite: StudySite;
  carer: StudyMember;
  patient: any;
  participantCode: string;
  status: StudyParticipantStatus;
}


export enum SessionStatus {
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Overdue = 'OVERDUE',
}

export class Session {
  id: number;
  participantSessionNumber: number;
  status: SessionStatus;
  scheduledAt: string;
  isRescheduled: boolean;
  createdAt: string;            // datetime in ISO 8601 format
  updatedAt: string;
  completedAt: string;          // datetime in ISO 8601 format
  studyParticipant: StudyParticipant;
  studyMember: StudyMember;
  // ---------
  today?: boolean;
  past?: boolean;

  constructor() { }
}

export class SessionCreateBody {
  scheduledAt: string;
  siteMember: number;
  participant: number;
}
