export enum Permissions {
  LIST_STUDIES = 'list_studies',
  ADD_STUDY = 'add_study',
  VIEW_EDIT_STUDY = 'view_edit_study',
  DELETE_STUDY = 'delete_study',
  LIST_SITES = 'list_sites',
  ADD_SITE = 'add_site',
  EDIT_SITE = 'edit_site',
  DELETE_SITE = 'delete_site',
  ADD_CLINICAL_DEVICE = 'add_clinical_device',
  REMOVE_CLINICAL_DEVICE = 'remove_clinical_device',
  REMOVE_CLINICAL_DEVICES = 'remove_clinical_devices',
  LIST_PARTICIPANTS = 'list_participants',
  REGISTER_PARTICIPANT = 'register_participant',
  EDIT_PARTICIPANT = 'edit_participant',
  LIST_PARTICIPANT_SESSIONS = 'list_participant_sessions',
  WITHDRAW_PARTICIPANT = 'withdraw_participant',
  COMPLETE_PARTICIPANT = 'complete_participant',
  LIST_ADVERSE_EVENTS = 'list_adverse_events',
  ADD_ADVERSE_EVENT = 'add_adverse_event',
  EDIT_ADVERSE_EVENT = 'edit_adverse_event',
  DELETE_ADVERSE_EVENT = 'delete_adverse_event',
  LIST_SESSIONS = 'list_sessions',
  CALENDAR = 'calendar',
  SCHEDULE_SESSION = 'schedule_session',
  RESCHEDULE_SESSION = 'reschedule_session',
  CANCEL_SESSION = 'cancel_session',
  UPDATE_SESSION_DATA = 'update_session_data',
  COMPLETE_SESSION = 'complete_session'
}
