import { AuthService } from '@services/auth.service';
import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { Permissions } from '@models/permissions.enum';
import { PermissionRolesMap } from '@models/permissions.db';

@Directive({
  selector: '[permission]',
  standalone: true
})
export class PermissionDirective implements OnChanges {

  @Input() permission: Permissions;

  constructor(
    private authService: AuthService,
    private elementRef: ElementRef
  ) { }

  ngOnChanges() {
    if (!this.hasAccess) {
      this.hideElement();
    }
  }

  private get hasAccess() {
    const userRoles = this.authService.roles;

    const requiredRoles = PermissionRolesMap[this.permission];
    return requiredRoles.some(role => userRoles.includes(role));
  }

  hideElement() {
    this.elementRef.nativeElement.style.display = 'none';
    this.elementRef.nativeElement.remove();
  }
}
