
export class Pagination {
  size: number;
  start: number
  totalCount: number;
  filter?: object;
  sort?: Sort;

  static DEFAULT_SIZE = 10;

  constructor(size = Pagination.DEFAULT_SIZE, start = 0, totalCount = 0) {
    this.size = size;
    this.start = start;
    this.totalCount = totalCount;
  }

  onSort(prop: string, order: OrderType) {
    this.sort = { prop, order };
    this.start = 0;
  }

  onFilter(filter: object) {
    this.filter = filter;
    this.start = 0;
  }
}

export class PaginationResponse<T> {
  data: T[];
  pagination: Pagination;
}

export class Sort {
  prop: string;
  order: OrderType;
}

export enum OrderType {
  ASC = 'asc',
  DESC = 'desc'
}

export class UpdateResponse {
  message: string;
  data: {
    id: string
  };
}
export class CreateResponse {
  message: string;
  data: {
    id: string
  };
}

export class GetOneResponse<T> {
  data: T;
}
